@import 'tooltip.css';
@import 'select.css';
@import 'scrollbars.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
        font-family: DMSans;
        src: url('../fonts/DMSans-Regular/DMSans-Regular.ttf') format('truetype'),
        url('../fonts/DMSans-Regular/DMSans-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        text-rendering: optimizeLegibility;
    }
    @font-face {
        font-family: DMSans;
        src: url('../fonts/DMSans-Italic/DMSans-Italic.ttf') format('truetype'),
        url('../fonts/DMSans-Italic/DMSans-Italic.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        text-rendering: optimizeLegibility;
    }
    @font-face {
        font-family: DMSans;
        src: url('../fonts/DMSans-Medium/DMSans-Medium.ttf') format('truetype'),
        url('../fonts/DMSans-Medium/DMSans-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        text-rendering: optimizeLegibility;
    }
    @font-face {
        font-family: DMSans;
        src: url('../fonts/DMSans-MediumItalic/DMSans-MediumItalic.ttf') format('truetype'),
        url('../fonts/DMSans-MediumItalic/DMSans-MediumItalic.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        text-rendering: optimizeLegibility;
    }
    @font-face {
        font-family: DMSans;
        src: url('../fonts/DMSans-Bold/DMSans-Bold.ttf') format('truetype'),
        url('../fonts/DMSans-Bold/DMSans-Bold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        text-rendering: optimizeLegibility;
    }
    @font-face {
        font-family: DMSans;
        src: url('../fonts/DMSans-BoldItalic/DMSans-BoldItalic.ttf') format('truetype'),
        url('../fonts/DMSans-BoldItalic/DMSans-BoldItalic.woff') format('woff');
        font-weight: 600;
        font-style: italic;
        text-rendering: optimizeLegibility;
    }

    html {
        @apply font-dm tracking-document selection:bg-c-blue selection:text-white text-sm lg:text-base;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    svg {
        @apply transition-all duration-200;
    }
}


@layer components {
    .login-bg {
        @apply relative before:pointer-events-none before:z-10
        before:absolute before:inset-0 before:w-full before:h-full
        before:bg-gradient-to-b before:bg-black before:opacity-25;
    }

    .project-desc {
        @apply overflow-hidden relative;
        height: 5.625rem;
    }
    .project-desc:before {
        @apply block absolute right-0 bottom-0 w-1/4 text-center pointer-events-none;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%);
        content: '\200C';
    }

    .step:first-child a:before {
        content: none;
    }

    .step-title span {
        @apply text-title relative before:bg-c-light-cyan before:absolute before:bottom-1.5
        before:left-0 before:right-0 before:h-4 before:w-full before:-z-10;
    }

    .menu-simplebar {
        @apply relative z-20 overflow-x-hidden h-screen;
    }
    .menu-simplebar .simplebar-content {
        @apply h-full;
    }

    .pdf-download {
        @apply inline-flex items-center font-bold transition-all duration-200
        text-base rounded border-2 border-transparent hover:bg-white
        text-white bg-c-blue hover:border-c-blue hover:text-c-blue justify-center p-2;
    }

    .field-warning {
        @apply border-orange-400 placeholder-orange-400 !important;
    }

    .faq-content ul {
        @apply list-disc list-inside;
    }
}

@layer utilities {
    .vertical-center {
        @apply absolute top-1/2 transform -translate-y-1/2;
    }
    .horizontal-center {
        @apply absolute left-1/2 transform -translate-x-1/2;
    }

    .checkbox-input {
        @apply cursor-pointer absolute opacity-0 h-0 w-0;
    }
    .action {
        @apply flex;
    }
    .action label {
        @apply w-full;
    }
    .action .checkbox-input:checked + .checkbox-label {
        @apply bg-c-light-cyan bg-opacity-25 border-opacity-15;
    }
    .action .checkbox-label {
        @apply transition-all p-0 min-h-anticipate border rounded-sm border-c-light-cyan
        before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2
        after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2;
    }
    .action .checkbox-label.error {
        @apply border-red-400 before:border-red-400;
    }
    .checkbox-label {
        @apply relative block pl-7 text-sm font-medium
        before:w-4.5 before:h-4.5 before:absolute before:left-0 before:top-px before:rounded
        before:border before:border-c-light-cyan peer-checked:before:border-c-cyan
        before:bg-transparent peer-checked:before:bg-c-cyan
        before:transition-all
        after:w-4.5 after:h-4.5 after:absolute after:left-0 after:top-px
        after:bg-checkbox after:bg-no-repeat after:bg-center after:bg-checkbox-size
        after:opacity-0 after:transition-all peer-checked:after:opacity-100;
    }

    .radio-input {
        @apply cursor-pointer absolute opacity-0 h-0 w-0;
    }
    .radio-label {
        @apply relative block pl-6 font-medium text-xs
        before:w-4.5 before:h-4.5 before:absolute before:left-0 before:-top-0.5
        before:border before:border-c-blue
        before:bg-transparent
        before:transition-all before:rounded-full
        after:w-3 after:h-3 after:absolute after:left-[2.5px] lg:after:left-[3px] after:top-[0.075rem]
        after:bg-c-blue after:rounded-full
        after:opacity-0 after:transition-all peer-checked:after:opacity-100;
    }

    .checkbox-incompatible input {
        @apply cursor-pointer absolute opacity-0 h-0 w-0;
    }
    .checkbox-incompatible .checkbox-inner {
        @apply w-full min-h-anticipate relative cursor-pointer border border-c-light-cyan
        rounded-sm transition-all
        before:absolute before:inset-0 before:bg-c-orange before:opacity-0 before:transition-all
        after:bg-cross after:bg-no-repeat after:bg-center after:w-3.5 after:h-3.5 after:opacity-0
        after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2
        after:transition-all;
    }
    .checkbox-incompatible input:checked ~ .checkbox-inner {
        @apply border-c-orange before:opacity-100 after:opacity-100;
    }

    .checkbox-trajectory .checkbox-label {
        @apply min-h-anticipate relative before:top-1/2 before:transform before:-translate-y-1/2 before:left-1/2 before:-translate-x-1/2
         after:top-1/2 after:transform after:-translate-y-1/2 after:left-1/2 after:-translate-x-1/2;
    }
    .checkbox-trajectory {
        @apply cursor-pointer;
    }
    .checkbox-trajectory input:checked ~ .checkbox-label {
        @apply before:bg-c-blue before:border-c-blue;
    }
    .checkbox-trajectory.disabled {
        @apply cursor-not-allowed;
    }
    .checkbox-trajectory.disabled .checkbox-label {
        @apply before:border-c-gray/10;
    }

    .input-trajectory {
        @apply block w-full max-w-trajectories text-center text-sm rounded-sm font-bold text-c-blue p-4 placeholder-c-gray outline-0 text-ellipsis
        overflow-hidden transition-shadow duration-200 shadow-c-input focus:shadow-c-input-hover
        rounded border border-c-light-cyan p-3.25 min-h-anticipate;
    }
}