
.simplebar-content-wrapper {
    @apply outline-none;
}

.simplebar-scrollbar {
    @apply before:bg-gray-500;
}

.table-simplebar .simplebar-scrollbar::before {
    @apply opacity-40 !important;
}

.table-simplebar .simplebar-wrapper {
    @apply pb-5 !important;
}

.table-inner .simplebar-track {
    @apply bg-c-gray/5 rounded-full;
}

.table-inner .simplebar-content-wrapper {
    @apply overflow-y-visible !important;
}