
.react-select-container, .react-select__control {
    @apply outline-none shadow-none text-center text-c-blue font-bold text-sm !important;
}

.react-select__control {
    @apply border-c-blue rounded-sm cursor-pointer px-2 py-3 min-h-anticipate !important;
}

.react-select__value-container {
    @apply p-0 !important;
}

.react-select__menu-list {
    @apply p-0 !important;
}

.react-select__single-value {
    @apply text-c-blue !important;
}

.react-select__option--is-focused {
    @apply bg-transparent !important;
}
.react-select__option {
    @apply hover:bg-c-light-cyan/30 !important;
}
.react-select__option--is-selected {
    @apply bg-c-blue/100 !important;
}

.react-select__indicators {
    @apply hidden !important;
}

.react-select__option {
    @apply cursor-pointer p-1.5 !important;
}

.react-select__menu {
    @apply border border-t-0 border-c-blue rounded-t-none
    rounded-sm shadow-none mb-0 mt-[-2px] !important;
}

.react-select__control--menu-is-open .react-select__value-container {
    @apply after:rotate-180;
}
.react-select__value-container {
    @apply after:transition-transform after:duration-300 after:w-5 after:h-5 after:absolute
    after:-translate-y-1/2 after:top-1/2 after:right-2 after:bg-select
    after:bg-no-repeat after:bg-center after:bg-select-size;
}




.react-select-anticipate .react-select-container, .react-select-anticipate .react-select__control {
    @apply text-left text-xs !important;
}

.react-select-anticipate .react-select__control {
    @apply border-c-light-cyan px-1 !important;
}

.react-select-anticipate .react-select__menu {
    @apply border-t border-b-gray-200 border-c-light-cyan rounded-t-sm mt-0 mb-[-2px] text-xs !important;
}

.react-select-anticipate .react-select__value-container {
    @apply pr-5 !important;
}

.react-select-anticipate .react-select__value-container {
    @apply after:right-0;
}

.react-select-anticipate .react-select__option {
    @apply py-3 !important;
}





.react-select-trajectories .react-select__control {
    @apply border-c-gray px-3 min-w-trajectories !important;
}

.react-select-trajectories .react-select__menu {
    @apply border-c-gray !important;
}

.react-select-trajectories .react-select__value-container {
    @apply pr-6 !important;
}

.react-select-trajectories .react-select__value-container {
    @apply after:right-0;
}

.react-select-trajectories .react-select__option {
    @apply py-3 !important;
}

.react-select-trajectories .react-select__value-container {
    @apply after:bg-selectG;
}

.react-select-trajectories .react-select-container, .react-select-trajectories .react-select__control {
    @apply text-c-gray !important;
}

.react-select-trajectories .react-select__placeholder {
    @apply text-c-gray !important;
}