
.rc-tooltip-inner {
    @apply rounded-1.5xl shadow-c-shadow p-0 border-0 !important;
}

.rc-tooltip-arrow {
    @apply border-t-white !important;
    margin-left: -9px !important;
    border-width: 6px 9px 0 !important;
}

.rc-tooltip {
    @apply bg-transparent !important;
}